import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar style={{ color: "#dcca87 !important" }} className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow mb-3" container dark>
                    <NavbarBrand href="/"><img alt="Simmer List logo" height="48px" src="/simmerlisttxt-03.png" /></NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} style={{ color: "#dcca87" }} className="mr-2 text-light" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink href="https://carbonscribe.com" style={{ color: "#dcca87" }}>CarbonScribe for Corporate Emissions Reporting</NavLink>
                            </NavItem>
                            {/*<NavItem>*/}
                            {/*    <NavLink href="https://restaurants.simmerlist.com" style={{ color: "#dcca87" }}>For Restaurants</NavLink>*/}
                            {/*</NavItem>*/}
                            <LoginMenu>
                            </LoginMenu>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}
